@import "./variables.scss";
@import "./mixins.scss";

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat';
    background-color: rgb(237, 237, 237);
    color: $black;
    //overflow-y: hidden;
}

.NoScroll {
    overflow-y: hidden !important;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.open {
    display: block !important;
}
p {
    line-height: 25px;
}

input {
    outline: none;
}

.p-datatable {
    height: 100% !important;
}

.p-datatable-tbody {
    font-size: 14px;
}

.p-datatable-thead {
    font-size: 14px;
}

.Field {
    position: relative;
}

.EyePwd {
    position: absolute;
    background: none;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);

    >svg {
        font-size: 16px;
        color: rgb(174, 174, 174);
    }
}

.AdminContainer {
    width: 100%;
    height: 100%;
    padding: 30px;

    @include tablet-portrait {
        padding: 30px 18px;
    }

    &-listTuile {
        height: calc(100% - 15%);
        overflow-y: scroll;
        margin-top: 40px;
        row-gap: 50px;
        padding: 20px 0;

        @include mobile-landscape {
            margin-top: 30px !important;
            row-gap: 100px;
            height: calc(100vh - 300px);
        }
    }
}

.p-button {
    font-weight: 400;
    height: 35px;
    font-size: 15px !important;
    padding: 0 10px !important;
}

.ListProjectDataTable {
    height: 100%;
    width: 100%;

    &-dataTable {
        overflow-x: scroll;
        border: 1px solid red;
    }

    &-groupBtn {
        display: flex;
        gap: 6px;
        width: max-content;
    }

    &-btn {
        padding: 8px 10px;
        border-radius: 4px;
        color: white;
        font-size: 14px;
    }

    &-btnReview {
        background: $green;
    }

    &-btnEdit {
        background-color: $orange;
    }

    &-btnDelete {
        background: $red;
    }

    &-btnTask {
        background-color: $blue;
    }

    &-btnReport {
        background-color: rgb(0, 176, 129);
    }

    &-iconVideo {
        font-size: 30px;
    }

    &-planningBlock {
        height: calc(100vh - 280px);
    }
}

.p-dialog {
    width: 600px;

    @include mobile-landscape {
        width: 90% !important;
        //height: 90vh !important;
    }
}

.DialogBtnOk {
    width: max-content;
    padding: 8px 10px;
    background-color: $green;
    color: white;
    margin: auto;
    border-radius: 5px;
}

/* ******* reparation ********* */
.TitleSection {
    font-size: 22px;
    font-weight: 500px;
}