@import "../../global/variables.scss";
@import "../../global/mixins.scss";

.Auth {
    background-color: white;
    height: auto;
    width: 680px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;

    @include mobile-landscape {
        width: 370px;
    }

    &-closeForm {
        position: absolute;
        top: 6px;
        right: 10px;
        font-size: 40px;
        background: none;
        z-index: 3;
    }

    &-imgBox {
        width: 50%;
        overflow: hidden;
        border-radius: 20px;
        background: url(../../assets/images/plan1.png) center no-repeat;
        background-size: cover;
        position: relative;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.405);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #94bd005b;
        }

        @include mobile-landscape {
            display: none;
        }
    }
}

.FormAuth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 35px 40px 35px;
    width: 50%;

    @include mobile-landscape {
        width: 100%;
    }

    @include mobile-portrait {
        padding: 30px 20px;
        overflow-y: scroll;
    }

    &-pwdForgot {
        font-size: 13px;
        color: $green;
    }

    &-h1 {
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;

        @include mobile-portrait {
            margin-bottom: 18px;
        }
    }

    &-subTitle {
        font-size: 13px;
        margin-bottom: 30px;
    }

    &-btnSubmit {
        background-color: $green;
        width: 100%;
        border-radius: 8px;
        padding: 12px 12px;
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: 5px;
        position: relative;
        overflow-wrap: break-word !important;

        @include mobile-portrait {
            margin-bottom: 15px;
        }
    }

    &-inputFile {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.Role {
    width: 100%;
    margin-bottom: 20px;
    gap: 10px;
    flex-wrap: wrap;

    &-label {
        font-size: 13px;
        white-space: nowrap;
    }

}

.Field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 22px;

    @include mobile-portrait {
        margin-bottom: 16px;
    }

    &>input {
        border: none;
        border-bottom: 1px solid $whitesmoke;
        outline: none;
        padding-bottom: 8px;
        font-size: 15px;
        color: $black;
    }

    &-code {
        letter-spacing: 10px;
    }

    &-error {
        font-size: 13px;
        color: $red;
    }

    &-boxFile {
        width: 100%;
        background-color: $whitesmoke-light;
        padding: 10px;
        border: 1px solid $whitesmoke;
        position: relative;
    }

    &-listFile {
        height: 70px;
        overflow-y: scroll;
    }

    &-fileDiv {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $whitesmoke;
        padding: 5px 0;

        >span {
            font-size: 12px;
            width: 80%;
            text-transform: capitalize;
        }

        >button {
            background: none;
            color: red;
            font-size: 20px;
        }
    }

    &-addField {
        background: $red;
        color: white;
        padding: 5px;
        border-radius: 6px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: -20px;
        right: -25px;
        z-index: 1000;
        width: auto;
        font-size: 12px;
    }
}

.PhoneField {
    width: 100%;
    margin-bottom: 20px;

    &-blockInput {
        width: 100%;
        border-bottom: 1px solid $whitesmoke;
        padding-bottom: 10px;

        &>input {
            width: 100%;
            margin-left: 6px;
            border: none;
            outline: none;
            font-size: 14px;
        }
    }
}

.FooterForm {
    margin-top: 20px;
    width: 100%;

    @include mobile-portrait {
        margin-top: 15px;
    }

    &-p {
        font-size: 12px;
        text-align: center;
        width: 100%;
    }

    &-btnAuth {
        background: none;
        color: $green;
        text-decoration: underline;
    }

    &-linksTerm {
        margin-top: 15px;
        color: rgb(155, 153, 153);

        @include mobile-portrait {
            margin-top: 5px;
        }

        &>a {
            font-size: 11px !important;
            color: rgb(155, 153, 153);
            white-space: nowrap;
        }
    }

    &-btnReg {
        width: 100%;
        padding: 12px 0;
        border: 1px solid $green;
        background-color: $green-transparent;
        border-radius: 8px;
        margin-top: 10px;
        text-transform: uppercase;
        font-size: 14px;
    }

    &-or {
        font-size: 20px;
        font-weight: bold;
        color: rgb(155, 153, 153);
    }
}