@import "./mixins.scss";

.Container {
  width: 1280px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include tablet-landscape {
    width: 100%;
  }
}



.PaddingLeftRight {
  padding-left: 25px;
  padding-right: 25px;

  @include mobile-portrait {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.PaddingTB {
  padding-top: 15px;
  padding-bottom: 25px;

  @include mobile-portrait {
    padding-bottom: 8rem !important;
  }
}

.DivScroll {
  overflow: auto;
  scrollbar-color: #aaa transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Ascenseur */
  &::-webkit-scrollbar-thumb {
    background: #aaaaaa0c;
    border-radius: 8px;
  }
}

.show {
  display: block !important;
  /* Utilisez !important pour garantir que cette règle l'emporte sur d'autres styles */
}

.hide {
  display: none !important;
}

.TitlePage {
  font-size: 25px;
  font-weight: 600;
  margin: 20px 0 30px;
}

.MBottomTop-6 {
  margin-bottom: 6rem;
  margin-top: 6rem;
}

.Dflex {
  display: flex;

  &-flexWrap {
    flex-wrap: wrap;
  }

  &-alignCenter {
    align-items: center;
  }

  &-col {
    flex-direction: column;
  }

  &-alignEnd {
    align-items: flex-end;
  }

  &-alignStart {
    align-items: flex-start;
  }

  &-justifyCCenter {
    justify-content: center;
  }

  &-justifySpaceBetween {
    justify-content: space-between;
  }
}

.w-full {
  width: 100%;
}

.EllipsisLine1,
.EllipsisLine2,
.EllipsisLine3,
.EllipsisLine4,
.EllipsisLine5 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: break-word;
}

.EllipsisLine1 {
  -webkit-line-clamp: 1;
}

.EllipsisLine2 {
  -webkit-line-clamp: 2;
}

.EllipsisLine3 {
  -webkit-line-clamp: 3;
}

.EllipsisLine4 {
  -webkit-line-clamp: 4;
}

.EllipsisLine5 {
  -webkit-line-clamp: 5;
}

.Close {
  position: absolute;
  font-size: 2rem;
  top: 1%;
  right: 1%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(45, 45, 45);
  color: white;
}