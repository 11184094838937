@import "../../global/variables.scss";
@import "../../global/mixins.scss";

.Sidebar {
    width: 250px !important;
    height: 100%;
    padding-top: 1px;
    border-right: 1px solid $whitesmoke;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;

    &-nb {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $green;
        color: white;
        position: absolute;
        right: 32%;
        top: 10%;
        font-size: 11px;
    }

    &-close {
        position: absolute;
        font-size: 30px;
        color: $black;
        right: 5px;
        cursor: pointer;
        top: 2px;
        background: none;
        display: none;

        @include tablet-portrait {
            display: block;
        }
    }

    .GroupBtnAuth {
        display: none;
    }

    @include tablet-portrait {
        position: absolute;
        top: 0;
        box-shadow: 0 10px 40px $black-shadow;
        z-index: 2;
        display: none;
        padding-top: 2rem;

        .GroupBtnAuth {
            display: block;
            

            &>button {
                width: 100%;
                margin-top: 15px;
            }

            &-btnLogin {
                background-color: $green-transparent;
            }
        }
    }

    &-foot {
        height: fit-content;
        padding: 0 20px;
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
        width: 100%;
        display: none;

        @include tablet-portrait {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    &-btnProfile {
        font-size: 14px;
        color: $green;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $green;
        border-radius: 4px;
        padding: 4px 12px;
        transition: 0.4s;
        text-transform: uppercase;

        &:hover {
            background: $green-transparent;
        }

        &>svg {
            font-size: 25px;
            margin-right: 10px;
        }
    }

    &-btnCreateProjet {
        font-size: 18px;
        text-transform: capitalize;
        margin: 20px;
        background: none;

        &>svg {
            font-size: 30px;
            color: $green;
            margin-right: 10px;
        }
    }

    &-li {
        position: relative;

        &:last-child {
            &>a {
                border-bottom: 1px solid $whitesmoke;
            }
        }
    }

    &-a {
        padding: 12px 0 12px 23px;
        border-top: 1px solid $whitesmoke;
        transition: 0.3s;
        cursor: pointer;
        position: relative;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
        width: 100%;

        &::before {
            content: "";
            height: 100%;
            background-color: $green;
            width: 6px;
            position: absolute;
            left: 0;
            top: 0;
            display: none;
        }

        &:hover {
            background-color: $green-transparent;

            &::before {
                display: block;
            }
        }

        &.active {
            background-color: $green-transparent;

            &::before {
                display: block;
            }
        }
    }

    &-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;

        &>svg {
            font-size: 20px;
            color: $green;
            margin-right: 12px;
        }
    }

    &-p {
        width: 85%;
        font-size: 13px;
        line-height: 20px;
    }

    &-arrow {
        font-size: 22px;
        color: $black;
        margin-right: 8px;
    }
}