@import "../../global/variables.scss";
@import "../../global/mixins.scss";

.NavBar {

    &-btnEstimate,
    &-btnProfile {
        display: none !important;
    }

    @include tablet-portrait {
        display: none;

        &-btnEstimate {
            display: block !important;
            width: 90%;
            margin: 10px auto;
        }

        &-btnProfile {
            display: flex !important;
            width: 90%;
            margin: 1rem 1rem;
            text-align: center;
            justify-content: center;
        }
    }

    &--open {
        display: block;
        transition: 0.7s;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.692);
    }

    &--hide {
        display: none !important;
    }

    &-ul {
        @include tablet-portrait {
            flex-direction: column;
            background-color: white;
            border: 1px solid $whitesmoke;
            padding: 10px 0;
            width: 300px;
            position: absolute;
            left: 0;
            top: 100%;
            box-shadow: 0 10px 10px $black-shadow;
            z-index: 2;
        }
    }

    &-li {
        margin: 0 8px;
        height: 70px;

        @include tablet-portrait {
            width: 100%;
            height: 38px;
        }

        &>a {
            height: 100%;
            display: flex;
            align-items: center;
            color: $black;
            font-size: 14px;
            text-transform: capitalize;
            position: relative;
            padding: 0 10px;

            &::before {
                content: "";
                background-color: $green;
                height: 4px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;

                @include tablet-portrait {
                    width: 4px;
                    height: 100%;
                }
            }

            &.active,
            &:focus,
            &:hover {
                background-color: $green-transparent;

                &::before {
                    display: block;
                }
            }

            @include tablet-portrait {
                width: 100%;
                padding-left: 20px;

                &:hover {
                    &::before {
                        display: none;
                    }
                }
            }

            &.active {
                background-color: $green-transparent;

                &::before {
                    display: block;
                }
            }
        }
    }

    &-right {
        width: max-content;

        @include tablet-portrait {
            width: 100%;
        }
    }
}