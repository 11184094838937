@import "../../global/variables.scss";
@import "../../global/mixins.scss";

.TopBar {
    height: 45px;
    border-bottom: 1px solid $whitesmoke;

    @include tablet-portrait {
        height: 40px;
    }

    @include mobile-portrait {
        margin-right: 0;
        flex-wrap: wrap !important;
    }

    &-left {
        color: $black;

        &>li {
            font-size: 13px;
            color: $black;
            cursor: pointer;
            padding-top: 2px;

            &:nth-child(3) {
                margin-left: 12px;
            }

            &.selected {
                color: $green;
                text-decoration: underline;
            }

            @include mobile-portrait {
                font-size: 13px;
            }
        }

        &.active {
            color: $green;
            border-bottom: 1px solid $green;
        }
    }

    &-sidebarIcon {
        display: none;

        @include tablet-portrait {
            margin-right: 16px;
            font-size: 25px;
            cursor: pointer;
            color: $black;
            display: block;
        }

        @include mobile-portrait {
            font-size: 20px;
        }
    }

    &-right {
        &>button {
            font-size: 16px;
            padding: 10px 30px;
            border-radius: 6px;
        }
    }
}

.SelectLang {
    position: relative;
    z-index: 1;
    cursor: pointer;

    @include tablet-portrait {
        margin-right: 0;
        border-right: none;
    }

    &-lang {
        font-size: 13px;
        text-align: center;
        color: white;
        width: 100%;

        @include mobile-portrait {
            font-size: 12px;
        }
    }

    &-langSelected {
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $green;
        border-radius: 50px;

        &>svg {
            color: white;
            font-size: 28px;
        }

        @include mobile-portrait {
            margin-right: 0;
            font-size: 18px;
            height: 20px;
        }

    }

    &-items {
        position: absolute;
        background-color: white;
        border: 1px solid $whitesmoke;
        display: flex;
        flex-direction: column;
        width: 62px;
        left: 0;

        &>button {
            background: none;
            font-size: 14px;
            padding: 2px;

            &:hover {
                background: $green-transparent;
            }
        }
    }
}