@import "../../global/variables.scss";
@import "../../global/mixins.scss";

.GroupBtnAuth {
    width: 100%;
    &>button {
        font-size: 16px;
        border-radius: 6px;
    }

    &-btnLogout,
    &-btnLogin {
        color: $green;
        text-decoration: underline;
    }

    &-btnLogout {
        padding: 0 !important;
    }

    &-btnLogout,
    &-btnLogin {
        background: none;

        @include tablet-portrait {
            text-decoration: none;
            border: 1px solid $green;
            width: 100% !important;
            padding: 10px 0px !important;
            background: $green;
            color: white;
        }
    }

    &-btnRegister {
        background: $green;
        color: white;
    }
}