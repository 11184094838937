.BlockAuthSocial {
    &-title {
        font-size: 12px;
        text-align: center;
        margin-bottom: 13px;
    }

    &-groupBtn {
        &>button {
            background: none;
            margin: 0 25px;

            &>img {
                width: 28px;
            }
        }
    }
}

.FormAuthSocial {
    width: 100%;
}