@import "../../global/variables.scss";
@import "../../global/mixins.scss";

.Footer{
    background: white;
    border-top: 1px solid #85a803;
    //min-height: 100vh;

    a{
        color: $black;
        font-size: 14px;

        &:hover {
            text-decoration: underline;
        }
    }

    &-top {
        height: calc(100vh - 80px);
        padding: 30px 0;

        @include tablet-landscape {
            height: auto;
        }
    }

    &-bottom {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
        font-size: 14px;
        border-top: 1px solid rgb(214, 214, 214);
        text-align: center;

        @include tablet-portrait {
            height: auto;
            padding: 20px 0;
            flex-direction: column;
            justify-content: center;
            gap: 6px;
         }

        a {
            &,&:visited {
                color: inherit;
                font-size: inherit;
            }
        }
    }

    &-linkBottom {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        @include tablet-portrait {
            gap: 5px;
            align-items: center;
            justify-content: center;
        }
    }

    &-topSocial {
        width: 100%;
        border-bottom: 1px solid rgb(214, 214, 214);
        display: flex;
        justify-content: flex-end;
        padding-bottom: 30px;

        ul {
            display: flex;
            gap: 15px;

            a {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $green;

                svg {
                    fill: white;
                    font-size: 20px;
                }
            }
        }
    }

    &-center {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 50px;

        @include tablet-portrait {
           gap: 30px;
        }
    }

    &-li {
        padding: 6px 0;
    }

    &-title {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 20px;
        //text-transform: capitalize;
    }

    &-form {
        width: 450px;
    }

    &-inputs {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-btnSendForm {
        background: $green !important;
        border-color: $green !important;
        height: 45px !important;

        &:hover {
            background: #92b90456 !important;
            color: $green !important;
            
        }
    }
}

.SectionFooter {
    background: $green;
    height: 100px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: white;
    font-size: 14px;

    @include mobile-portrait {
        height: auto;
        padding: 20px 10px;
     }

    &-country {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 25px;
        font-size: 20px;
    }
}