@import "../global/variables.scss";
@import "../global/mixins.scss";

.Layout {
    width: 100%;
    background-color: white;
    height: 100%;
    //overflow-y: scroll;

    &-body {
        //min-height: 100vh;
        //padding-bottom: 100px;

        @include tablet-portrait {
            ///height: calc(100vh - 100px);
        }
    }

    &-bodyAuth {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Header {
    &-sideBarIcon {
        display: none;
        font-size: 26px;
        color: $black;
        margin-right: 16px;
        cursor: pointer;

        @include tablet-portrait {
            display: block;
        }
    }

    &--hide {
        display: none;
    }

    &-menuBar {
        height: 70px;

        @include tablet-portrait {
            position: relative;
            height: 60px;
        }
    }

    &-linklogo {
        display: flex;
        align-items: end;
        
        &>a {
            display: flex;
            align-items: end;

            &>img {
                width: 40px;

                @include tablet-portrait {
                    width: 32px;
                }
            }

            &>span {
                color: $black;
                font-size: 20px;
                margin-left: 15px;
                font-weight: 600;
                height: fit-content;
                white-space: nowrap;

                @include tablet-portrait {
                    font-size: 18px;
                }
            }
        }
    }

    &-btnEstimate {
        padding: 10px 15px;
        background: $green;
        color: white;
        border-radius: 6px;
        font-size: 15px;
        text-transform: uppercase;

        @include tablet-portrait {
            display: none;
        }
    }

    &-btnProfile {
        font-size: 14px;
        color: $green;
        position: relative;
        background: none;
        display: flex;
        align-items: center;
        border: 1px solid $green;
        border-radius: 4px;
        padding: 4px 12px;
        transition: 0.4s;
        text-transform: uppercase;

        &:hover {
            background: $green-transparent;
        }

        @include tablet-portrait {
            display: none;
        }

        &>svg {
            font-size: 25px;
            margin-right: 10px;
        }
    }
}

.Main {
    width: 100%;
    height: calc(100vh - 130px);
    display: flex;

    &-center {
        height: 100%;
        width: calc(100% - 250px);
        position: relative;
        display: block;

        @include tablet-portrait {
            width: 100%;
        }
    }

    &-mainCenter {
        width: 100%;
        height: 90%;
        overflow-y: scroll;
        padding: 20px 20px 50px 20px;
    }

    &-head {
        height: 71px;
        box-shadow: 0 1px 1px $black-shadow;
        width: 100%;
        padding-left: 10px;
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;

        @include tablet-portrait {
            height: 50px;
        }
    }

    &-dialogNotify {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        font-size: 14px;
    }
}