$blue: #3622B7;
$blue-700: #271884;
$blue-transparent: #F2F6FF;
$red: #ff404d;
$red-700: #c73b44;
$red-transparent: #FFF0F1;
$orange-hover: #ba5e08;
$orange: #dd6f07;
$black: #444444;
$black-shadow: #00000022;
$whitesmoke: #cdcdcd;
$text-color: #868686;
$whitesmoke-light: rgb(245, 245, 245);
$green: #94bd00;
$green-transparent: #94bd0032;
$transition-duration: 0.4s