@import "../../global/variables.scss";

.Checkbox {
    display: flex;
    align-items: center;

    &-box {
        height: 21px;
        width: 21px;
        position: relative;
        cursor: pointer;

        &>input {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;
            opacity: 0;
        }
    }

    &-label {
        font-size: 13px;
        margin-left: 6px;
    }

    &-round {
        height: 100%;
        width: 100%;
        border: 2px solid $green;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: "";
            position: absolute;
            height: 70%;
            width: 70%;
            background-color: $green;
            border-radius: 50%;
            display: none;
        }
    }

    &-box input:checked~&-round:before {
        display: block;
    }
}